import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type OrganizationTypeCodes =
  | "REAA0123"
  | "REAA01PR"
  | "REAA01RE"
  | "REAA0118"
  | "REAA010J"
  | "REAA011C"
  | "REAA0113"
  | "REAA0127"
  | "REAA0104"
  | "REAA011F"
  | "REAA0115"
  | "REAA0124"
  | "REAA010A"
  | "REAA010N"
  | "REAA011B"
  | "REAA011D"
  | "REAA0101"
  | "REAA01"
  | "REAA0106"
  | "REAA01OT"
  | "REAA0105"
  | "REAA0102"
  | "REAA010G"
  | "REAA010D"
  | "REAA0130"
  | "REAA0110"
  | "REAA0114"
  | "REAA01ZP"
  | "REAA0109"
  | "REAA010H"
  | "REAA010I"
  | "REAA010C"
  | "REAA0129"
  | "REAA0112"
  | "REAA0108"
  | "REAA01SP"
  | "REAA0126"
  | "REAA0103"
  | "REAA010K"
  | "REAA010E"
  | "REAA0125"
  | "REAA011E"
  | "REAA0116"
  | "REAA0121"
  | "REAA010F"
  | "REAA01PM"
  | "REAA01PC"
  | "REAA010L"
  | "REAA01ZR"
  | "REAA0119"
  | "REAA0107"
  | "REAA0120"
  | "REAA0117"
  | "REAA011A"
  | "REAA0122"
  | "REAA0111"
  | "REAA0128"
  | "REAA010B"
  | "REAA010M";

export const allOrganizationBoardMembersTypeCodes = [
  "REAA0123",
  "REAA01PR",
  "REAA01RE",
  "REAA0118",
  "REAA010J",
  "REAA011C",
  "REAA0113",
  "REAA0127",
  "REAA0104",
  "REAA011F",
  "REAA0115",
  "REAA0124",
  "REAA010A",
  "REAA010N",
  "REAA011B",
  "REAA011D",
  "REAA0101",
  "REAA01",
  "REAA0106",
  "REAA01OT",
  "REAA0105",
  "REAA0102",
  "REAA010G",
  "REAA010D",
  "REAA0130",
  "REAA0110",
  "REAA0114",
  "REAA01ZP",
  "REAA0109",
  "REAA010H",
  "REAA010I",
  "REAA010C",
  "REAA0129",
  "REAA0112",
  "REAA0108",
  "REAA01SP",
  "REAA0126",
  "REAA0103",
  "REAA010K",
  "REAA010E",
  "REAA0125",
  "REAA011E",
  "REAA0116",
  "REAA0121",
  "REAA010F",
  "REAA01PM",
  "REAA01PC",
  "REAA010L",
  "REAA01ZR",
  "REAA0119",
  "REAA0107",
  "REAA0120",
  "REAA0117",
  "REAA011A",
  "REAA0122",
  "REAA0111",
  "REAA0128",
  "REAA010B",
  "REAA010M",
];

export function useGetTypeName() {
  const { t } = useTranslation("ucet");
  return useCallback(
    (typeCode: string) => {
      switch (typeCode) {
        case "REAA0123":
          return t("ucet.organizationType.REAA0123", "Místopředseda výboru");
          break;
        case "REAA01PR":
          return t("ucet.organizationType.REAA01PR", "Předseda rady");
          break;
        case "REAA01RE":
          return t("ucet.organizationType.REAA01RE", "Revizor");
          break;
        case "REAA0118":
          return t("ucet.organizationType.REAA0118", "Společník");
          break;
        case "REAA010J":
          return t("ucet.organizationType.REAA010J", "Vedoucí");
          break;
        case "REAA011C":
          return t(
            "ucet.organizationType.REAA011C",
            "Vedoucí odštěpného závodu"
          );
          break;
        case "REAA0113":
          return t("ucet.organizationType.REAA0113", "Člen burzovní komory");
          break;
        case "REAA0127":
          return t("ucet.organizationType.REAA0127", "Místopředseda prezídia");
          break;
        case "REAA0104":
          return t("ucet.organizationType.REAA0104", "Předseda představenstva");
          break;
        case "REAA011F":
          return t("ucet.organizationType.REAA011F", "Člen správní rady");
          break;
        case "REAA0115":
          return t("ucet.organizationType.REAA0115", "Člen bankovní rady");
          break;
        case "REAA0124":
          return t("ucet.organizationType.REAA0124", "Člen výboru");
          break;
        case "REAA010A":
          return t("ucet.organizationType.REAA010A", "Jednatel");
          break;
        case "REAA010N":
          return t("ucet.organizationType.REAA010N", "Předseda společenství");
          break;
        case "REAA011B":
          return t("ucet.organizationType.REAA011B", "Komanditista");
          break;
        case "REAA011D":
          return t("ucet.organizationType.REAA011D", "Člen výkonného výboru");
          break;
        case "REAA0101":
          return t("ucet.organizationType.REAA0101", "Guvernér");
          break;
        case "REAA01":
          return t("ucet.organizationType.REAA01", "Orgán společnosti");
          break;
        case "REAA0106":
          return t("ucet.organizationType.REAA0106", "Člen představenstva");
          break;
        case "REAA01OT":
          return t("ucet.organizationType.REAA01OT", "Statutár");
          break;
        case "REAA0105":
          return t(
            "ucet.organizationType.REAA0105",
            "Místopředseda představenstva"
          );
          break;
        case "REAA0102":
          return t("ucet.organizationType.REAA0102", "Předseda");
          break;
        case "REAA010G":
          return t("ucet.organizationType.REAA010G", "Podnikatel");
          break;
        case "REAA010D":
          return t("ucet.organizationType.REAA010D", "Starosta");
          break;
        case "REAA0130":
          return t("ucet.organizationType.REAA0130", "Hejtman");
          break;
        case "REAA0110":
          return t("ucet.organizationType.REAA0110", "Člen zastupitelstva");
          break;
        case "REAA0114":
          return t("ucet.organizationType.REAA0114", "Předseda bankovní rady");
          break;
        case "REAA01ZP":
          return t(
            "ucet.organizationType.REAA01ZP",
            "Zástupce předsedy představenstva"
          );
          break;
        case "REAA0109":
          return t("ucet.organizationType.REAA0109", "Člen dozorčí rady");
          break;
        case "REAA010H":
          return t("ucet.organizationType.REAA010H", "Předseda družstva");
          break;
        case "REAA010I":
          return t("ucet.organizationType.REAA010I", "Místopředseda družstva");
          break;
        case "REAA010C":
          return t("ucet.organizationType.REAA010C", "Primátor");
          break;
        case "REAA0129":
          return t(
            "ucet.organizationType.REAA0129",
            "Místopředseda výkonného výboru"
          );
          break;
        case "REAA0112":
          return t(
            "ucet.organizationType.REAA0112",
            "Místopředseda burzovní komory"
          );
          break;
        case "REAA0108":
          return t(
            "ucet.organizationType.REAA0108",
            "Místopředseda dozorčí rady"
          );
          break;
        case "REAA01SP":
          return t("ucet.organizationType.REAA01SP", "Správce");
          break;
        case "REAA0126":
          return t("ucet.organizationType.REAA0126", "Předseda prezídia");
          break;
        case "REAA0103":
          return t("ucet.organizationType.REAA0103", "Ředitel");
          break;
        case "REAA010K":
          return t("ucet.organizationType.REAA010K", "Člen družstva");
          break;
        case "REAA010E":
          return t("ucet.organizationType.REAA010E", "Místostarosta");
          break;
        case "REAA0125":
          return t("ucet.organizationType.REAA0125", "Pověřený vlastník");
          break;
        case "REAA011E":
          return t("ucet.organizationType.REAA011E", "Předseda správní rady");
          break;
        case "REAA0116":
          return t("ucet.organizationType.REAA0116", "Člen prezídia");
          break;
        case "REAA0121":
          return t(
            "ucet.organizationType.REAA0121",
            "Místopředseda správní rady"
          );
          break;
        case "REAA010F":
          return t("ucet.organizationType.REAA010F", "Tajemník");
          break;
        case "REAA01PM":
          return t("ucet.organizationType.REAA01PM", "Místopředseda rady");
          break;
        case "REAA01PC":
          return t("ucet.organizationType.REAA01PC", "Člen rady");
          break;
        case "REAA010L":
          return t("ucet.organizationType.REAA010L", "Člen");
          break;
        case "REAA01ZR":
          return t("ucet.organizationType.REAA01ZR", "Zástupce ředitele");
          break;
        case "REAA0119":
          return t("ucet.organizationType.REAA0119", "Jiná");
          break;
        case "REAA0107":
          return t("ucet.organizationType.REAA0107", "Předseda dozorčí rady");
          break;
        case "REAA0120":
          return t(
            "ucet.organizationType.REAA0120",
            "Náměstek primátora / starosty"
          );
          break;
        case "REAA0117":
          return t("ucet.organizationType.REAA0117", "Komplementář");
          break;
        case "REAA011A":
          return t(
            "ucet.organizationType.REAA011A",
            "Vedoucí organizační složky"
          );
          break;
        case "REAA0122":
          return t("ucet.organizationType.REAA0122", "Předseda výboru");
          break;
        case "REAA0111":
          return t(
            "ucet.organizationType.REAA0111",
            "Předseda burzovní komory"
          );
          break;
        case "REAA0128":
          return t(
            "ucet.organizationType.REAA0128",
            "Předseda výkonného výboru"
          );
          break;
        case "REAA010B":
          return t("ucet.organizationType.REAA010B", "Prokurista");
          break;
        case "REAA010M":
          return t("ucet.organizationType.REAA010M", "Místopředseda");
          break;
        default:
          return t("ucet.organizationType.UNKNOWN", "Neznámý");
      }
    },
    [t]
  );
}

export const useGetAllCodesOptions = () => {
  const findName = useGetTypeName();
  return allOrganizationBoardMembersTypeCodes.map((code) => ({
    label: findName(code),
    id: code.toString(),
  }));
};
