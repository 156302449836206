import { HelpOutlineRounded } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {
  AlertsContext,
  DistaTooltip,
  DistaTooltipVariant,
} from "@sista/dista-ui";
import { IdmClientContext, ucetAppConfig } from "@sista/idm-client";
import {
  CreateOrganizationRegistrationDTO,
  Organization,
  OrganizationRegistrationRequestDTO,
  PleaseWait,
  Right,
  SistaId,
  SmartButton,
  UiContext,
  useMaxLengthValidation,
  useMinLengthValidation,
  usePager,
  useRequiredValidation,
  useSafeSistaAsync,
  logi,
  SistaAutocompleteElement,
  countryCodes,
  countryLabels,
} from "@sista/library-stasi";
import { useIcoValidation } from "@sista/library-stasi/dist/components/form/validations/useIcoValidation";
import { SistaFistoSingle } from "@sista/service-fisto-ui";
import { SistaSpravaUkoluList } from "@sista/service-workflow-ui";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  CheckboxElement,
  FormContainer,
  RadioButtonGroup,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OrganizationInfoContent } from "./OrganizationInfoContent";
import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { ControlHandle } from "../../../utils/controlHandle";
import { TempFilesElement } from "../../ui/TempFilesElement";

export type OrganizationRegisterForm = CreateOrganizationRegistrationDTO & {
  agree: boolean;
};

export type OrganizationCreateDelegateContentProps = {
  organization: Organization;
  registration?: OrganizationRegistrationRequestDTO;
  backControl: ControlHandle;
};

export const OrganizationCreateDelegateContent = (
  props: OrganizationCreateDelegateContentProps
) => {
  const ico = useIcoValidation();
  const maxLength = useMaxLengthValidation(8);
  const minLength = useMinLengthValidation(8);
  const required = useRequiredValidation();
  const { showInfo } = useContext(AlertsContext);
  const rej = useRejstrikClient();

  const { t } = useTranslation("ucet");

  const { confirm, closeDialogs } = useContext(UiContext);

  const navigate = useNavigate();

  const { registerOrganization, updateOrganizationRegistration } =
    useRejstrikClient();

  const [registerOrganizationCall, registerOrganizationState] =
    useSafeSistaAsync(registerOrganization);

  const [
    updateOrganizationRegistrationCall,
    updateOrganizationRegistrationState,
  ] = useSafeSistaAsync(updateOrganizationRegistration);

  const [
    revokeOrganizationRegistrationCall,
    revokeOrganizationRegistrationState,
  ] = useSafeSistaAsync(rej.revokeOrganizationRegistration);

  const { loggedAccount, refresh } = useContext(IdmClientContext);

  const verificationRequest =
    registerOrganizationState.value || props.registration;
  const fileToPrint = verificationRequest?.registrationFileId;

  const pager = usePager(0);

  useEffect(() => {
    logi("Verification request: ", verificationRequest, pager.page);
    // Zaciname ...
    if (pager.page == 0) {
      // ... musime ho hodit na spravnou stranku registrace:
      if (verificationRequest != null) {
        pager.setPage(2);
      } else {
        pager.setPage(1);
      }
    }
  }, [pager, verificationRequest]);

  const registrationForm = useForm<OrganizationRegisterForm>({
    defaultValues: {
      representation: "STATUTAR",
      agree: false,
      attachmentIds: [],

      // ...props.profile,
      // section: storedSection,
      // info: storedInfo,
    },
  });

  const finishRegistrationForm = useForm({
    defaultValues: {
      deliveryMethod: null as string,
      signedFileId: null as string,
    },
  });

  const submitRegistrationForm = useCallback(
    async (formData: OrganizationRegisterForm) => {
      const confirmed = await confirm({
        title: t(
          "ucet.organization.delegate.confirmRegistration",
          "Potvrzení registrace"
        ),
        question: t(
          "ucet.organization.delegate.confirmRegistrationText",
          "Opravdu chcete zaregistrovat jako správce pro tuto organizaci?"
        ),
      });
      if (!confirmed) return;
      const r = await registerOrganizationCall({
        ...formData,
        organizationId: props.organization?.id,
      });
      if (r != null && r.requestId != null) {
        logi("Moving to page 2");
        pager.setPage(2);
      }
    },
    [pager, props.organization?.id, registerOrganizationCall, confirm, t]
  );

  const submitUpdateRegistrationForm = useCallback(
    async (formData: {
      signedFileId: string | null;
      deliveryMethod: string;
    }) => {
      await updateOrganizationRegistrationCall({
        organizationId: props.organization?.id,
        ...verificationRequest,
        signedFileId: formData.signedFileId,
        deliveryMethod: formData.deliveryMethod,
      });
      pager.setPage(4);
    },
    [
      updateOrganizationRegistrationCall,
      props.organization?.id,
      verificationRequest,
      pager,
    ]
  );

  const delegateOptions = useDelegateOptions();
  const deliveryOptions = useDeliveryOptions();

  const finishProcess = useCallback(() => {
    showInfo(
      t(
        "ucet.organization.delegate.success",
        "Registrace zaslána na TAČR, nyní prosím vyčkejte na vyřízení."
      )
    );
    closeDialogs();
    navigate("/");
  }, [navigate, closeDialogs, showInfo, t]);

  const revokeOrganizationRegistration = useCallback(async () => {
    const revokeConfirmed = await confirm({
      title: t("ucet.organization.delegate.revokeTitle", "Zrušení registrace"),
      question: t(
        "ucet.organization.delegate.revokeQuestion",
        "Opravdu chcete zrušit registraci?"
      ),
    });
    if (!revokeConfirmed) return;
    const revoke = await revokeOrganizationRegistrationCall({
      organizationId: props.organization?.id,
      organizationRequestId: verificationRequest?.requestId,
    });
    if (typeof revoke === "object" && revoke) {
      showInfo(
        t(
          "ucet.organization.delegate.revokeSuccess",
          "Registrace byla zrušena."
        )
      );
      closeDialogs();
    }
  }, [
    closeDialogs,
    props.organization?.id,
    revokeOrganizationRegistrationCall,
    showInfo,
    t,
    verificationRequest?.requestId,
    confirm,
  ]);

  const attachmentUploadContext = useMemo(
    () => [
      new SistaId("organizations", props.organization.id).asUrn(),
      new SistaId("profiles", loggedAccount.activeProfileId).asUrn(),
    ],
    [loggedAccount.activeProfileId, props.organization.id]
  );

  const attachmentReadContext = useMemo(
    () => [new SistaId("organizations", props.organization.id).asUrn()],
    [props.organization.id]
  );

  const taskContext = useMemo(() => {
    if (verificationRequest?.requestId == null) return [];
    return [
      new SistaId("organizations", props.organization.id).asUrn(),
      new SistaId("workflows", "organization_registration").asUrn(),
      new SistaId(
        "organizationRegistration",
        verificationRequest?.requestId
      ).asUrn(),
    ];
  }, [props.organization.id, verificationRequest?.requestId]);

  logi(registrationForm.watch("attachmentIds"));

  const canContinueInSendPhase =
    finishRegistrationForm.watch("deliveryMethod") != null &&
    (finishRegistrationForm.watch("deliveryMethod") != "file" ||
      finishRegistrationForm.watch("signedFileId") != null);

  // Vysvetlime rodici, co znamena jit zpet
  useEffect(() => {
    if (pager.page <= 3 && pager.page >= 2) {
      // sem se musi dat fce, co teprve vraci fci, jinak ji R hned zavola
      // jelikoz je debil
      props.backControl.setCallback(() => pager.prevPage);
    } else {
      props.backControl.setCallback(null);
    }
  }, [pager.page, pager.prevPage, props.backControl]);

  return (
    <>
      {pager.page == 1 ? (
        <>
          <FormContainer
            onSuccess={submitRegistrationForm}
            formContext={registrationForm}
          >
            <Stack direction="column" spacing={2} mt={2} ml={1}>
              <Alert severity="info" sx={{}}>
                {t(
                  "ucet.organization.delegate.createNew",
                  "U této organizace neevidujeme zodpovědnou osobu, která ji může zastupovat. Pokud jste to vy, vyplňte prosím následující formulář."
                )}
              </Alert>
              <OrganizationInfoContent organization={props.organization} />
              <Divider />
              <Typography variant="h5" component="h5">
                {t(
                  "ucet.organization.delegate.variantHeadline",
                  "Jakým způsobem subjekt zastupujete?"
                )}
                <DistaTooltip
                  title={t(
                    "ucet.organization.delegate.variantTooltip",
                    'Vyberte takovou možnost, která odráží způsob, jakým subjekt zastupujete. V případě volby "Jednám za subjekt na základě pověření" budete v rámci vygenerované žádosti vyzváni k doložení pověření k zastupování členem statutárního orgánu daného subjektu.'
                  )}
                  variant={DistaTooltipVariant.info}
                >
                  <HelpOutlineRounded
                    color="primary"
                    sx={{
                      ml: 0.5,
                      width: "16px",
                    }}
                  />
                </DistaTooltip>
              </Typography>

              <RadioButtonGroup
                name="representation"
                options={delegateOptions}
              />

              <Divider />

              <Typography variant="h5" component="h5">
                {t("ucet.organization.delegate.attachmentsHeadline", "Přílohy")}
                <DistaTooltip
                  title={t(
                    "ucet.organization.delegate.attachmentsTooltip",
                    "Zde je možné doložit další relevantní dokumenty související s žádostí o registraci subjektu v SISTA, např. k prokázání existence společnosti zakládací listinu či jiný obdobný dokument, pokud tak není možné zjistit z volně dostupných informací."
                  )}
                  variant={DistaTooltipVariant.info}
                >
                  <HelpOutlineRounded
                    color="primary"
                    sx={{
                      ml: 0.5,
                      width: "16px",
                    }}
                  />
                </DistaTooltip>
              </Typography>

              <TempFilesElement
                name={"attachmentIds"}
                context={attachmentUploadContext}
                multiple={true}
                label={t(
                  "ucet.organization.delegate.attachmentsButtonLabel",
                  "Přidat"
                )}
              />

              <Divider />
              <Typography variant="h5" component="h5">
                {t(
                  "ucet.organization.delegate.agreeHeadline",
                  "Souhlas s podmínkami registrace"
                )}
              </Typography>

              <CheckboxElement
                label={
                  <Stack direction={"column"} spacing={1}>
                    <Typography variant="body1">
                      {t(
                        "ucet.organization.delegate.agreeText",
                        "Souhlasím s podmínkami registrace subjektu do SISTA a správy uživatelů/osob"
                      )}
                    </Typography>
                    <Typography variant="body1Sm">
                      {t(
                        "ucet.organization.delegate.termsLinkText1",
                        "Podmínky registrace jsou ke stažení v "
                      )}
                      <Link
                        href="https://www.tacr.cz/dokumenty/souhlas-s-podminkami-registrace-subjektu-do-sista-a-spravy-uzivatelu"
                        target="_blank"
                      >
                        {t(
                          "ucet.organization.delegate.termsLinkCZ",
                          "CS verzi"
                        )}
                      </Link>
                      {t("ucet.organization.delegate.termsLinkText2", " a ")}
                      <Link
                        href="https://www.tacr.cz/dokumenty/agreement-to-the-terms-and-conditions-of-registration-of-the-organisation-with-sista"
                        target="_blank"
                      >
                        {t(
                          "ucet.organization.delegate.termsLinkEN",
                          "EN verzi"
                        )}
                      </Link>
                      {t("ucet.organization.delegate.termsLinkText3", ".")}
                    </Typography>
                  </Stack>
                }
                name="agree"
                validation={required}
                sx={{ alignSelf: "flex-start" }}
              />

              <PleaseWait state={registerOrganizationState} />

              <Right sx={{ marginTop: 4 }}>
                <SmartButton state={registerOrganizationState}>
                  {t(
                    "ucet.organization.delegate.prepareDocument",
                    "Pokračovat"
                  )}
                </SmartButton>
              </Right>
            </Stack>
          </FormContainer>
        </>
      ) : null}
      {pager.page == 2 ? (
        <>
          <Stack direction="column" spacing={2} mt={2} ml={1}>
            <Typography variant={"body1"}>
              {t(
                "ucet.organization.delegate.printAndSign",
                "V tomto kroku si stáhněte žádost ve formátu PDF. Nechte podepsat v souladu se stanovami vaší organizace. Následně nahrajete dokument do systému opatřený platným elektronickým podpisem nebo odešlete s využitím Datové schránky, případně v listinné podobě poštou."
              )}
            </Typography>
            <SistaFistoSingle
              variant={"link"}
              fileId={fileToPrint}
              authKey={loggedAccount.authKey}
              deleteDisabled={true}
            />
            <Stack direction={"row"} sx={{ marginTop: 4 }}>
              <Button
                variant={"outlined"}
                color="error"
                onClick={() => revokeOrganizationRegistration()}
              >
                {t("ucet.organization.delegate.cancel", "Zrušit")}
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant={"contained"} onClick={() => pager.setPage(3)}>
                {t("ucet.organization.delegate.signDocument", "Pokračovat")}
              </Button>
            </Stack>
          </Stack>
        </>
      ) : null}
      {pager.page == 3 ? (
        <>
          <FormContainer
            onSuccess={submitUpdateRegistrationForm}
            formContext={finishRegistrationForm}
          >
            <Stack direction="column" spacing={2} mt={2} ml={1}>
              <Typography variant={"body1"}>
                {t(
                  "ucet.organization.delegate.sendInfo",
                  "Nyní nahrajte elektronicky podepsanou žádost do systému (kvalifikovaným elektronickým podpisem).\n V případě, že jste žádost odeslal s využitím informaníčho systému datových schránek z datové schránky vaší organizace, zmačněte tlačítko odesláno s využitím datové schránky.\n Pokud jste odeslal žádost poštou, pak vyberte tuto možnost a následně stiskněte tlačítko pokračovat."
                )}
              </Typography>

              <RadioButtonGroup
                name="deliveryMethod"
                options={deliveryOptions}
              />

              {finishRegistrationForm.watch("deliveryMethod") === "file" && (
                <TempFilesElement
                  multiple={false}
                  name={"signedFileId"}
                  label={t(
                    "ucet.organization.delegate.uploadSignedFile",
                    "Nahrát podepsanou žádost"
                  )}
                  context={attachmentUploadContext}
                />
              )}

              <Right sx={{ marginTop: 4 }}>
                <SmartButton
                  variant={"contained"}
                  disabled={!canContinueInSendPhase}
                  state={updateOrganizationRegistrationState}
                >
                  {t("ucet.organization.delegate.sendDocument", "Pokračovat")}
                </SmartButton>
              </Right>
            </Stack>
          </FormContainer>
        </>
      ) : null}
      {pager.page == 4 ? (
        <>
          <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
            {t(
              "ucet.organization.delegate.closeRegistrationTask",
              "Zaneste prosím do systému informaci o dokončeném úkolu a předejte proces registrace na TAČR."
            )}
          </Alert>
          <SistaSpravaUkoluList
            loggedAuthKey={loggedAccount.authKey}
            tasksContext={taskContext}
            spravaUkoluRoot={"/sprava-ukolu"}
            onSuccess={finishProcess}
          />
        </>
      ) : null}
    </>
  );
};

export function useDelegateOptions() {
  const { t } = useTranslation("ucet");

  return useMemo(
    () => [
      {
        id: "STATUTAR",
        label: t(
          "ucet.organization.delegate.statutar",
          "Jsem členem statutárního orgánu subjektu (např. jednatel...)"
        ),
      },
      {
        id: "AUTHORIZATION",
        label: t(
          "ucet.organization.delegate.authorization",
          "Jednám za subjekt na základě pověření (např. plná moc...)"
        ),
      },
    ],
    [t]
  );
}
export function useDeliveryOptions() {
  const { t } = useTranslation("ucet");
  return useMemo(
    () => [
      {
        id: "file",
        label: t("ucet.organization.delegate.delivery.file", "Nahrání žádosti"),
      },
      {
        id: "isds",
        label: t(
          "ucet.organization.delegate.delivery.isds",
          "Odeslání datovou schránkou"
        ),
      },
      {
        id: "post",
        label: t(
          "ucet.organization.delegate.delivery.post",
          "Odesláno v listinné podobě"
        ),
      },
    ],
    [t]
  );
}
