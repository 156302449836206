import { useTranslation } from "react-i18next";

export const useDecimalValidation = (decPlaces: number) => {
  const { t } = useTranslation("ucet");

  return {
    validate: (value?: string) => {
      if (value == null) return undefined;
      if (value === "") return undefined;
      // it's simple whole number
      if (value.toString().match(/^\d+$/)) return undefined;

      // . or , decimal number with max decPlaces decimal places
      if (
        value.toString().match(new RegExp(`^\\d+([.,]\\d{1,${decPlaces}})?$`))
      )
        return undefined;
      return t(
        "common.error.wrongDecimal",
        "Zadejte číslo s maximálně {{decPlaces}} desetinnými místy.",
        { decPlaces }
      );
    },
  };
};
