import { Box, CircularProgress } from "@mui/material";
import { isInOauthMode } from "@sista/idm-client";
import { useContext, useEffect } from "react";

import { IdmUiContext } from "../../utils/IdmUiContext";
// import { Beta3WidgetDefaults } from "@sista/service-needs";

export const RedirectToDashboardScreen = () => {
  // redirect to: /dashboard/

  const { registrationIntent } = useContext(IdmUiContext);

  useEffect(() => {
    if (registrationIntent != null) return;
    const delay = isInOauthMode() ? 5000 : 200;
    setTimeout(() => {
      window.location.href = "/dashboard/";
    }, delay);
  }, [registrationIntent]);

  return (
    <Box sx={{ p: 2 }}>
      <CircularProgress />
    </Box>
  );
};
