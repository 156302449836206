import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  LoadingGuard,
  Organization,
  OrganizationOwnership,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { AddOrUpdateOwnershipDialog } from "../../../components/dialogs/organization/AddOrUpdateOwnershipDialog";
import { useGetOwnershipTieTypeName } from "../../../utils/useOrganizationOwnerShipTypes";
import { AlertsContext, DistaConfirmDialog } from "@sista/dista-ui";

export type OrganizationPropertyTiesContentProps = {
  organization: Organization;
};

export type RenderTiesProps = {
  tieCode: number;
  ownerships: any[];
  isForeign?: boolean;
  onUpdate?: (ownership: ExtendedOrganizationOwnership) => void;
  onRemove?: (ownership: ExtendedOrganizationOwnership) => void;
};

export const RenderTies: React.FC<RenderTiesProps> = (
  props: RenderTiesProps
) => {
  const { t } = useTranslation("ucet");
  const isForeign = useMemo(() => {
    return props.isForeign ?? false;
  }, [props.isForeign]);

  return (
    <Stack direction={"column"} spacing={2} sx={{ alignItems: "center" }}>
      {props.ownerships &&
        props.ownerships.length > 0 &&
        props.ownerships?.map((owner: any) => {
          if (owner.direction !== props.tieCode) return null;
          return (
            <Stack
              key={owner.id ? owner.id : owner.name + owner.type_cd}
              direction={"column"}
              sx={{ width: "100%" }}
            >
              {isForeign && (
                <Stack direction={"row"} spacing={2} sx={{}}>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button variant="text" onClick={() => props.onUpdate(owner)}>
                    {t("ucet.organizationPropertyTies.update", "Upravit")}
                  </Button>
                  <Button
                    variant="text"
                    color={"error"}
                    onClick={() => props.onRemove(owner)}
                  >
                    {t("ucet.organizationPropertyTies.remove", "Odebrat")}
                  </Button>
                </Stack>
              )}
              <Stack direction={"row"} spacing={2} sx={{}}>
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {t("ucet.organizationPropertyTies.name", "Název: ")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
                  {owner.name ? owner.name : "-"}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2} sx={{}}>
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {t("ucet.organizationPropertyTies.tieName", "Název vazby: ")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {owner.type_txt ? owner.type_txt : "-"}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2} sx={{}}>
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {t("ucet.organizationPropertyTies.part", "Procento: ")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body1Hl" sx={{ fontWeight: "normal" }}>
                  {owner.value_pct ? owner.value_pct : "-"}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          );
        })}
    </Stack>
  );
};

export type ExtendedOrganizationOwnership = OrganizationOwnership & {
  id: string;
};

export const OrganizationPropertyTiesContent = (
  props: OrganizationPropertyTiesContentProps
) => {
  const { t } = useTranslation("ucet");
  const rej = useRejstrikClient();

  const { showSuccess } = useContext(AlertsContext);

  const [allOwnerships, setAllOwnerships] =
    useState<ExtendedOrganizationOwnership[]>(null);

  const [showAddOwnerShipDialog, setShowAddOwnerShipDialog] = useState(false);
  const [showUpdateOwnerShipDialog, setShowUpdateOwnerShipDialog] =
    useState(false);
  const [showRemoveOwnerShipDialog, setShowRemoveOwnerShipDialog] =
    useState(false);
  const [selectedOwnership, setSelectedOwnership] =
    useState<ExtendedOrganizationOwnership>(null);

  const [updateNeeded, setUpdateNeeded] = useState(false);
  const [filled, setFilled] = useState(false);
  const [boardCount, setBoardCount] = useState(1);

  const [getOrganizationCustomDataCall, getOrganizationCustomDataState] =
    useSafeSistaAsync(rej.getOrganizationCustomData);

  const [addOrUpdatedOwnershipsCall, addOrUpdatedOwnershipsState] =
    useSafeSistaAsync(rej.createOrUpdateForeignOrganizationOwnership);

  useEffect(() => {
    getOrganizationCustomDataCall({ organizationId: props.organization.id });
  }, [props.organization.id, getOrganizationCustomDataCall]);

  const organizationCustomData = useMemo(() => {
    if (getOrganizationCustomDataState.value) {
      return getOrganizationCustomDataState.value;
    }
    return {};
  }, [getOrganizationCustomDataState.value]);

  const isForeign = useMemo(() => {
    return props.organization?.officialAddress?.stateCode !== "CZE";
  }, [props.organization]);

  const ownerships = useMemo(() => {
    return (organizationCustomData as any)?.rejstrik?.ownerships ?? null;
  }, [organizationCustomData]);

  let fillCount = 1;

  useEffect(() => {
    if (isForeign && ownerships != null && !filled) {
      const newOwnerships: ExtendedOrganizationOwnership[] = ownerships.map(
        (owner: any) => {
          const newMember = {
            id: fillCount.toString(),
            ...owner,
          };
          fillCount++;
          return newMember;
        }
      );
      setBoardCount(fillCount);
      setAllOwnerships(newOwnerships);
      setFilled(true);
    }
  }, [isForeign, ownerships, filled, fillCount]);

  const onCloseHandler = useCallback(() => {
    setShowAddOwnerShipDialog(false);
    setShowUpdateOwnerShipDialog(false);
    setShowRemoveOwnerShipDialog(false);
  }, []);

  const getOnlyOwnerships = useCallback(
    (ownerships: ExtendedOrganizationOwnership[]) => {
      if (ownerships == null) return [];
      return ownerships.map(({ id, ...rest }) => rest);
    },
    []
  );

  const findName = useGetOwnershipTieTypeName();

  const addOwnerShipHandler = useCallback(
    (data: ExtendedOrganizationOwnership) => {
      setShowAddOwnerShipDialog(false);
      setUpdateNeeded(true);
      const prevData = allOwnerships ?? [];
      const newOwnerShip: ExtendedOrganizationOwnership = {
        id: boardCount.toString(),
        end_dt: null,
        born_dt: null,
        strt_dt: null,
        inac_ind: "00",
        ...data,
      };
      newOwnerShip.type_txt = findName(data.type_cd);
      //console.log("data type_cd : " + data.type_cd);
      //console.log("nazev type : " + findName(data.type_cd));
      const newData = [...prevData, newOwnerShip];
      //console.log("newData", JSON.stringify(newData));
      setAllOwnerships(newData);
      setBoardCount(boardCount + 1);
    },
    [allOwnerships, boardCount, findName]
  );

  const openUpdateDialogHandler = useCallback(
    (ownerShip: ExtendedOrganizationOwnership) => {
      setSelectedOwnership(ownerShip);
      setShowUpdateOwnerShipDialog(true);
    },
    []
  );

  const updateBoardMemberHandler = useCallback(
    (data: ExtendedOrganizationOwnership) => {
      setShowUpdateOwnerShipDialog(false);
      setUpdateNeeded(true);
      const updatedOwnerships = allOwnerships.map((ownership) => {
        if (ownership.id === data.id) {
          return { ...data, type_txt: findName(data.type_cd) };
        }
        return ownership;
      });
      setAllOwnerships(updatedOwnerships);
    },
    [allOwnerships, findName]
  );

  const openRemoveDialogHandler = useCallback(
    (ownerShip: ExtendedOrganizationOwnership) => {
      setSelectedOwnership(ownerShip);
      setShowRemoveOwnerShipDialog(true);
    },
    []
  );

  const removeOwnershipHandler = useCallback(
    (data: ExtendedOrganizationOwnership) => {
      setUpdateNeeded(true);
      const updatedMembers = allOwnerships.filter(
        (ownership) => ownership.id !== data.id
      );
      setAllOwnerships(updatedMembers);
    },
    [allOwnerships]
  );

  const addOrUpdateOwnershipsHandler = useCallback(async () => {
    if (allOwnerships == null) return;
    const sendingOwnersips = [...getOnlyOwnerships(allOwnerships)] as any;
    sendingOwnersips.organizationId = props.organization.id;
    const result = await addOrUpdatedOwnershipsCall(sendingOwnersips);
    const message = t(
      "ucet.organization.info.boardMemberAddedOrUpdated",
      "Změny byly uloženy"
    );
    if (typeof result === "object" && result) {
      showSuccess(message);
    }
    setUpdateNeeded(false);
  }, [
    allOwnerships,
    addOrUpdatedOwnershipsCall,
    props.organization.id,
    t,
    showSuccess,
    getOnlyOwnerships,
  ]);

  useEffect(() => {
    /* console.log("allOwnerships", JSON.stringify(allOwnerships)); */
    if (updateNeeded) {
      addOrUpdateOwnershipsHandler();
    }
  }, [allOwnerships, updateNeeded, addOrUpdateOwnershipsHandler]);

  return (
    <Paper variant="infoCard" sx={{ mt: 2, minWidth: "50%" }}>
      <LoadingGuard state={getOrganizationCustomDataState}>
        {isForeign && (
          <Stack direction={"row"} spacing={0} sx={{}}>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="text"
              onClick={() => setShowAddOwnerShipDialog(true)}
            >
              {t("ucet.organizationPropertyTies.add", "Přidat")}
            </Button>
          </Stack>
        )}
        <Grid container infoCardMultiColumns>
          <Grid item md={6} xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(
                "ucet.organizationPropertyTies.propertyTitle",
                "Majetkové vazby"
              )}
            </Typography>
            {!isForeign && ownerships && (
              <RenderTies tieCode={-1} ownerships={ownerships} />
            )}
            {isForeign && allOwnerships && (
              <RenderTies
                tieCode={-1}
                ownerships={allOwnerships}
                isForeign={isForeign}
                onUpdate={openUpdateDialogHandler}
                onRemove={openRemoveDialogHandler}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("ucet.organizationPropertyTies.custodyTitle", "Řídící vazby")}
            </Typography>
            {!isForeign && ownerships && (
              <RenderTies tieCode={1} ownerships={ownerships} />
            )}
            {isForeign && allOwnerships && (
              <RenderTies
                tieCode={1}
                ownerships={allOwnerships}
                isForeign={isForeign}
                onUpdate={openUpdateDialogHandler}
                onRemove={openRemoveDialogHandler}
              />
            )}
          </Grid>
        </Grid>
      </LoadingGuard>
      {showAddOwnerShipDialog && (
        <AddOrUpdateOwnershipDialog
          onDone={addOwnerShipHandler}
          onCancel={onCloseHandler}
        />
      )}
      {showUpdateOwnerShipDialog && (
        <AddOrUpdateOwnershipDialog
          ownership={selectedOwnership}
          onDone={updateBoardMemberHandler}
          onCancel={onCloseHandler}
        />
      )}
      {showRemoveOwnerShipDialog && (
        <DistaConfirmDialog
          onClose={onCloseHandler}
          open={true}
          okAction={{
            type: "button",
            children: t(
              "ucet.organizationPropertyTies.ownership.confirmDialog.ok",
              "Ano"
            ),
            onClick: () => {
              setShowRemoveOwnerShipDialog(false);
              return removeOwnershipHandler(selectedOwnership);
            },
          }}
          cancelAction={{
            type: "button",
            children: t(
              "ucet.organizationPropertyTies.ownership.confirmDialog.cancel",
              "Zrušit"
            ),
            onClick: () => onCloseHandler(),
          }}
          title={t(
            "ucet.organizationPropertyTies.ownership.confirmDialog.title",
            "Odebrat vazbu"
          )}
        >
          {t(
            "ucet.organizationPropertyTies.ownership.confirmDialog.removeBoardMember",
            "Opravdu chcete odebrat vazbu?"
          )}
        </DistaConfirmDialog>
      )}
    </Paper>
  );
};
