import { Grid, Stack } from "@mui/material";
import { AlertsContext } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  Organization,
  Relation,
  RelationDTO,
  Right,
  SistaId,
  SmartButton,
  useMaxLengthValidation,
  useMinLengthValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useIcoValidation } from "@sista/library-stasi/dist/components/form/validations/useIcoValidation";
import { SistaChip } from "@sista/sista-chip";
import { t } from "i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

export type OrganizationRegistrationForm = {
  idNumber: string;
  vatId: string;
  isCzechCompany: boolean;
  isForeignCompany: boolean;
};

export type OrganizationSearchByIcoContentProps = {
  onFoundOrganization: (organization: Organization) => void;
  onFoundRelationsInOrganization: (relations: RelationDTO[]) => void;
  isForeignCompanyChecked: (isForeignCompany: boolean) => void;
};

export const OrganizationSearchByIcoContent = (
  props: OrganizationSearchByIcoContentProps
) => {
  const ico = useIcoValidation();
  const maxLength = useMaxLengthValidation(8);
  const minLength = useMinLengthValidation(8);
  const required = useRequiredValidation();

  const { showError } = useContext(AlertsContext);

  const form = useForm<OrganizationRegistrationForm, unknown>({
    defaultValues: {
      idNumber: "",
      vatId: "",
      isCzechCompany: false,
      isForeignCompany: false,
    },
  });

  const ic = form.watch("idNumber");
  const vatId = form.watch("vatId");
  const urnIc = useMemo(() => {
    return new SistaId("organizations:ico", ic).asUrn();
  }, [ic]);
  const urnVatId = useMemo(() => {
    return new SistaId("organizations:ico", vatId).asUrn();
  }, [vatId]);
  const isCzechCompany = form.watch("isCzechCompany");
  const isForeignCompany = form.watch("isForeignCompany");

  const { loggedAccount } = useContext(IdmClientContext);

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(null);

  const {
    getOrCreateOrganizationByIcoWithForceRefresh,
    getMyRelationsInOrganization,
    getOrPrepareOrganizationByVatId,
  } = useRejstrikClient();

  const [
    getOrCreateOrganizationByIcoWithForceRefreshCall,
    getOrCreateOrganizationByIcoWithForceRefreshState,
  ] = useSafeSistaAsync(getOrCreateOrganizationByIcoWithForceRefresh);

  const [
    getOrPrepareOrganizationByVatIdCall,
    getOrPrepareOrganizationByVatIdState,
  ] = useSafeSistaAsync(getOrPrepareOrganizationByVatId);

  const [getMyRelationsInOrganizationCall, getMyRelationsInOrganizationState] =
    useSafeSistaAsync(getMyRelationsInOrganization);

  const submitForm = useCallback(
    async (formData: OrganizationRegistrationForm) => {
      if (formData.isCzechCompany) {
        const result = await getOrCreateOrganizationByIcoWithForceRefreshCall({
          ico: formData.idNumber,
          forceRefresh: true,
        });
        if (result == null) {
          return showError(
            t("ucet.organization.form.notFound", "Organizace nebyla nalezena")
          );
        }
        return result;
      }
      if (formData.isForeignCompany) {
        const result = await getOrPrepareOrganizationByVatIdCall({
          vatId: formData.vatId,
        });
        if (typeof result === "object" && result) {
          props.isForeignCompanyChecked(false);
          props.onFoundRelationsInOrganization([]);
          return props.onFoundOrganization(result);
        }
      }
      return null;
    },
    [
      getOrCreateOrganizationByIcoWithForceRefreshCall,
      getOrPrepareOrganizationByVatIdCall,
      props,
      showError,
    ]
  );

  useEffect(() => {
    if (isCzechCompany) {
      if (getOrCreateOrganizationByIcoWithForceRefreshState.value != null) {
        getMyRelationsInOrganizationCall({
          organizationId:
            getOrCreateOrganizationByIcoWithForceRefreshState.value.id,
        });
      }
    }
  }, [
    getMyRelationsInOrganizationCall,
    getOrCreateOrganizationByIcoWithForceRefreshState.value,
    isCzechCompany,
  ]);

  useEffect(() => {
    if (getMyRelationsInOrganizationState.value != null) {
      if (isCzechCompany) {
        props.onFoundOrganization(
          getOrCreateOrganizationByIcoWithForceRefreshState.value
        );
        props.isForeignCompanyChecked(true);
      }
      props.onFoundRelationsInOrganization(
        getMyRelationsInOrganizationState.value
      );
    }
  }, [
    isCzechCompany,
    isForeignCompany,
    getMyRelationsInOrganizationState.value,
    getOrCreateOrganizationByIcoWithForceRefreshState.value,
    getOrPrepareOrganizationByVatIdState.value,
    props,
  ]);

  return (
    <FormContainer onSuccess={submitForm} formContext={form}>
      <Stack direction="column" spacing={2} mt={2}>
        <CheckboxElement
          name="isCzechCompany"
          label={t("ucet.organization.form.isCzechCompany", "Organizace z ČR")}
          onChange={(e) => {
            if (e.target.checked) {
              return form.setValue("isForeignCompany", !e.target.checked);
            }
          }}
        />
        {isCzechCompany && (
          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item xs={12} sm={4}>
              <TextFieldElement
                name={"idNumber"}
                autoComplete={"off"}
                label={t("ucet.organization.form.idNumber", "IČ")}
                validation={{
                  ...required,
                  ...maxLength,
                  ...minLength,
                  ...ico,
                }}
              />
            </Grid>
            {ic && ic.length > 0 && ico.validate(ic) == undefined && (
              <Grid item xs={12} sm={8}>
                <SistaChip
                  urn={urnIc}
                  authToken={loggedAccount.authKey}
                  variant="small"
                />
              </Grid>
            )}
          </Grid>
        )}
        <CheckboxElement
          name="isForeignCompany"
          label={t(
            "ucet.organization.form.isForeignCompany",
            "Organizace ze zahraničí"
          )}
          onChange={(e) => {
            if (e.target.checked) {
              return form.setValue("isCzechCompany", !e.target.checked);
            }
          }}
        />
        {isForeignCompany && (
          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item xs={12} sm={4}>
              <TextFieldElement
                name={"vatId"}
                autoComplete={"off"}
                label={t("ucet.organization.form.vatId", "DIČ")}
                validation={{
                  ...required,
                }}
              />
            </Grid>
            {/* {vatId && vatId.length > 0 && (
              <Grid item xs={12} sm={8}>
                <SistaChip
                  urn={urnVatId}
                  authToken={loggedAccount.authKey}
                  variant="small"
                />
              </Grid>
            )} */}
          </Grid>
        )}
        <Right sx={{ marginTop: 4 }}>
          <SmartButton
            state={getOrCreateOrganizationByIcoWithForceRefreshState}
            disabled={!isCzechCompany && !isForeignCompany}
          >
            {t("ucet.organization.form.search", "Vyhledat organizaci")}
          </SmartButton>
        </Right>
      </Stack>
    </FormContainer>
  );
};
