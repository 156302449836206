import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  AlertsContext,
  DistaConfirmDialog,
  DistaInfoListBlock,
} from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  formatPlainDate,
  Organization,
  OrganizationBoardMember,
  SmartButton,
  UiContext,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { ShowCode } from "@sista/service-codetables";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { detailItem } from "../../../utils/detailitem";
import { useGetTypeName } from "../../../utils/useOrganizationBoardMemberTypes";
import { AddOrUpdateBoardMemberDialog } from "../../dialogs/organization/AddOrUpdateBoardMemberDialog";

const iconSize = "20px";

type OrganizationDetailInfoContentProps = {
  organization: Organization;
};

export type ExtendedOrganizationBoardMember = OrganizationBoardMember & {
  id: string;
};

export const toPlainDate = (str: string) => {
  console.log("Parsing date: " + str);
  const parts = str.toString().split(".");
  if (parts.length !== 3) return undefined;
  const day = parseInt(parts[0]);
  const month = parseInt(parts[1]);
  const year = parseInt(parts[2]);
  if (isNaN(day) || isNaN(month) || isNaN(year)) return undefined;
  console.log("Parsed date: " + day + "." + month + "." + year);
  if (year < 1900) return undefined;
  if (year > 2050) return undefined;
  if (month < 1 || month > 12) return undefined;
  if (day < 1 || day > 31) return undefined;
  return { day: day, month: month, year: year };
};

export type OfficialAddress = {
  zip?: string;
  city?: string;
  state?: string;
  region?: string;
  street: string;
  district?: string;
  locality?: string;
  streetNumber?: string;
};

export const buildAddress = (address: OfficialAddress) => {
  if (address == null) return "-";
  return (
    <Stack direction={"column"} spacing={0}>
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ alignItems: "center", justifyContent: "end" }}
      >
        <Typography variant="body1">
          {address.street ? address.street : address.city ? address.city : ""}
        </Typography>
        <Typography variant="body1">
          {address.streetNumber ? " " + address?.streetNumber : ""}
        </Typography>
      </Stack>
      {address.district && address.city && address.district !== address.city ? (
        <Typography variant="body1">{address.district}</Typography>
      ) : null}
      <Typography variant="body1">
        {address.city ? " " + address.city : ""}
      </Typography>
      <Typography variant="body1">{address.zip ? address.zip : ""}</Typography>
      {address.locality ? (
        <Typography variant="body1">{address.locality}</Typography>
      ) : null}
      {address.region ? (
        <Typography variant="body1">{address.region}</Typography>
      ) : null}
      {address.state ? (
        <Typography variant="body1">{address.state}</Typography>
      ) : null}
    </Stack>
  );
};

export type RenderBoardMemberProps = {
  member: ExtendedOrganizationBoardMember | any;
  isForeign: boolean;
  onUpdate?: (member: ExtendedOrganizationBoardMember) => void;
  onRemove?: (member: ExtendedOrganizationBoardMember) => void;
};

export const RenderBoardMember = ({
  member,
  isForeign,
  onUpdate,
  onRemove,
}: RenderBoardMemberProps) => {
  const { t } = useTranslation("ucet");
  return (
    <Stack direction={"column"} key={member.id}>
      {isForeign && (
        <Stack direction={"row"} spacing={0} sx={{}}>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="text"
            onClick={() => {
              onUpdate(member);
            }}
          >
            {t("ucet.organization.info.boardMember.update", "Upravit")}
            {/* <Edit sx={{ width: iconSize, height: iconSize }} /> */}
          </Button>
          <Button
            variant="text"
            color={"error"}
            onClick={() => {
              onRemove(member);
            }}
          >
            {t("ucet.organization.info.boardMember.remove", "Odebrat")}
            {/* <Delete
              color={"error"}
              sx={{ width: iconSize, height: iconSize }}
            /> */}
          </Button>
        </Stack>
      )}
      <Stack direction={"row"} spacing={2} sx={{}}>
        <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
          {member.type ? member.type : "-"}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
          {member.firstName ? member.firstName : ""}{" "}
          {member.lastName ? member.lastName : "-"}
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={2} sx={{}}>
        <Typography variant="body1" sx={{}}>
          {t("ucet.organization.info.executive.birthDate", "Datum narození:")}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="body1" sx={{}}>
          {isForeign
            ? member.birthDate
              ? formatPlainDate(member.birthDate)
              : "-"
            : member.birthDate
            ? member.birthDate
            : "-"}
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={2} sx={{}}>
        <Typography variant="body1" sx={{}}>
          {t(
            "ucet.organization.info.executive.funcitonDate",
            "Den vznikufunkce:"
          )}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="body1" sx={{}}>
          {isForeign
            ? member.from
              ? formatPlainDate(member.from)
              : "-"
            : member.from
            ? member.from
            : "-"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const OrganizationDetailInfoContent = (
  props: OrganizationDetailInfoContentProps
) => {
  const { t } = useTranslation("ucet");
  const { showSuccess } = useContext(AlertsContext);

  const isForeign = useMemo(() => {
    return props.organization.officialAddress?.stateCode !== "CZE";
  }, [props.organization.officialAddress?.stateCode]);

  const rej = useRejstrikClient();

  const [refreshMagnusWebCall, refreshMagnusWebState] = useSafeSistaAsync(
    rej.refreshMagnusWebReport
  );

  const [getOrganizationCustomDataCall, getOrganizationCustomDataState] =
    useSafeSistaAsync(rej.getOrganizationCustomData);

  const findName = useGetTypeName();

  const [
    createOrUpdateOrganizationBoardMemberCall,
    createOrUpdateOrganizationBoardMemberState,
  ] = useSafeSistaAsync(rej.createOrUpdateForeignOrganizationBoardMember);

  const { refresh } = useContext(UiContext);

  useEffect(() => {
    getOrganizationCustomDataCall({ organizationId: props.organization.id });
  }, [props.organization.id, getOrganizationCustomDataCall]);

  const organizationCustomData = useMemo(() => {
    if (getOrganizationCustomDataState.value) {
      return getOrganizationCustomDataState.value;
    }
    return {};
  }, [getOrganizationCustomDataState.value]);

  const [allBoardMembers, setAllBoardMembers] =
    useState<ExtendedOrganizationBoardMember[]>(null);

  const refreshOrganizationData = useCallback(async () => {
    const result = await refreshMagnusWebCall({
      organizationId: props.organization.id,
    });
    if (typeof result === "object" && result) {
      showSuccess(
        t("ucet.organization.info.refreshed", "Data byla aktualizována")
      );
      refresh();
    }
  }, [t, refreshMagnusWebCall, showSuccess, refresh, props.organization.id]);

  const contactData = useMemo(() => {
    const copyToClipboard = (text: string) => {
      showSuccess(t("ucet.organization.info.copied", "Zkopírováno"));
      navigator.clipboard.writeText(text);
    };

    return {
      label: (
        <Stack direction="row">
          <Typography variant={"h3Hl"} sx={{ flexGrow: 1 }}>
            {t("ucet.organization.info.contact.title", "Kontaktní údaje")}
          </Typography>
          {/*  <SmartButton
            variant="text"
            state={refreshMagnusWebState}
            onClick={() => refreshOrganizationData()}
          >
            {t("common.refreshMagnus", "Aktualizovat")}
          </SmartButton> */}
        </Stack>
      ),
      infoListItems: [
        detailItem(
          t("ucet.organization.info.contact.idDataBox", "ID datové schránky"),
          props.organization.idDataBox ? (
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "end" }}
            >
              {props.organization.idDataBox}
              <Button
                variant="text"
                onClick={() => {
                  copyToClipboard(props.organization.idDataBox);
                }}
              >
                <ContentCopy />
              </Button>
            </Stack>
          ) : (
            t("ucet.organization.info.contact.noDataBox", "-")
          )
        ),
        /* detailItem(
          t("ucet.organization.info.contact.email", "Email"),
          props.organization.email ? (
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "end" }}
            >
              {props.organization.email}
              <Button
                variant="text"
                onClick={() => {
                  copyToClipboard(props.organization.email);
                }}
              >
                <ContentCopy />
              </Button>
            </Stack>
          ) : (
            t("ucet.organization.info.contact.noEmail", "-")
          )
        ), */
        detailItem(
          t("ucet.organization.info.contact.residenceAddress", "Sídlo"),
          buildAddress((organizationCustomData as any)?.system?.officialAddress)
        ),
        /* detailItem(
          t("ucet.organization.info.contact.address", "Adresa"),
          buildAddress(props.organization.contactAddress)
        ), */
      ],
    };
  }, [
    //refreshOrganizationData,
    //refreshMagnusWebState,
    t,
    showSuccess,
    props.organization.idDataBox,
    organizationCustomData,
    //props.organization.email,
    //props.organization.contactAddress,
    //props.organization.officialAddress,
  ]);

  const [boardCount, setBoardCount] = useState(1);

  const organizationMembers = useMemo(() => {
    return (organizationCustomData as any)?.rejstrik?.representation?.board
      ?.members;
  }, [organizationCustomData]);

  const [naplneno, setNaplneo] = useState(false);

  let fillCount = 1;

  useEffect(() => {
    if (isForeign && organizationMembers != null && !naplneno) {
      const newMembers: ExtendedOrganizationBoardMember[] =
        organizationMembers.map((member: any) => {
          const newMember = {
            id: fillCount.toString(),
            ...member,
          };
          newMember.birthDate = toPlainDate(member.birthDate);
          newMember.from = toPlainDate(member.from);
          fillCount++;
          return newMember;
        });
      setBoardCount(fillCount);
      setAllBoardMembers(newMembers);
      setNaplneo(true);
    }
  }, [organizationMembers, fillCount, isForeign, naplneno]);

  const managementManagers = useMemo(() => {
    return (organizationCustomData as any)?.rejstrik?.management?.managers;
  }, [organizationCustomData]);

  const getOrganizationUnitTypeLabel = useCallback(
    (typeId: string) => {
      if (typeId === "UNIVERSITY") {
        return t("ucet.organization.units.type.university", "Univerzita");
      } else if (typeId === "FACULTY") {
        return t("ucet.organization.units.type.faculty", "Fakulta");
      } else if (typeId === "DEPARTMENT") {
        return t("ucet.organization.units.type.department", "Katedra");
      } else if (typeId === "COMPANY") {
        return t("ucet.organization.units.type.company", "Firma");
      } else if (typeId === "RESEARCH_AGENCY") {
        return t(
          "ucet.organization.units.type.researchAgency",
          "Výzkumná agentura"
        );
      } else if (typeId === "MINISTRY") {
        return t("ucet.organization.units.type.ministry", "Ministerstvo");
      } else if (typeId === "PART") {
        return t("ucet.organization.units.type.part", "Organizační jednotka");
      } else {
        return typeId;
      }
    },
    [t]
  );

  const organizationUnitData = useMemo(() => {
    return {
      label: (
        <Stack direction="row">
          <Typography variant={"h6"} sx={{ flexGrow: 1 }}>
            {t("ucet.organization.info.organizationUnitDetail.title", "Info")}
          </Typography>
          {/* <SmartButton
          variant="text"
          state={refreshMagnusWebState}
          onClick={() => refreshOrganizationData()}
        >
          {t("common.refreshMagnus", "Aktualizovat")}
        </SmartButton> */}
        </Stack>
      ),
      infoListItems: [
        detailItem(
          t(
            "ucet.organization.info.organizationUnitDetail.code",
            "Kód organizační jednotky"
          ),
          props.organization.code ? (
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "end" }}
            >
              {props.organization.code}
            </Stack>
          ) : (
            t("ucet.organization.info.organizationUnitDetail.noCode", "-")
          )
        ),
        detailItem(
          t(
            "ucet.organization.info.organizationUnitDetail.type",
            "Typ organizační jednotky"
          ),
          props.organization.type ? (
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "end" }}
            >
              {getOrganizationUnitTypeLabel(props.organization.type)}
            </Stack>
          ) : (
            t("ucet.organization.info.organizationUnitDetail.noType", "-")
          )
        ),
      ],
    };
  }, [
    t,
    props.organization.code,
    props.organization.type,
    getOrganizationUnitTypeLabel,
  ]);

  const executiveData = useMemo(() => {
    return (
      <Stack direction={"column"} spacing={2} sx={{ mx: 2 }}>
        <Typography variant="h5">
          {t("ucet.organization.info.executive.title", "Statutární orgán")}
        </Typography>
        {!isForeign &&
          organizationMembers &&
          organizationMembers.map((member: any) => {
            return (
              <RenderBoardMember
                key={member.id}
                member={member}
                isForeign={false}
              />
            );
          })}
        {isForeign &&
          allBoardMembers?.length > 0 &&
          allBoardMembers.map((member: ExtendedOrganizationBoardMember) => {
            return (
              <RenderBoardMember
                key={member.id}
                member={member}
                isForeign={true}
                onUpdate={(member: ExtendedOrganizationBoardMember) => {
                  setSelectedBoardMember(member);
                  setShowUpdateBoardMemberDialog(true);
                }}
                onRemove={(member: ExtendedOrganizationBoardMember) => {
                  setSelectedBoardMember(member);
                  setShowRemoveBoardMemberDialog(true);
                }}
              />
            );
          })}
      </Stack>
    );
  }, [t, organizationMembers, isForeign, allBoardMembers]);

  const managementData = useMemo(() => {
    return (
      <Stack direction={"column"} spacing={2} sx={{ mx: 2 }}>
        <Typography variant="h5">
          {t("ucet.organization.info.management.title", "Vedení")}
        </Typography>
        {managementManagers && managementManagers.length > 0 ? (
          managementManagers.map((manager: any) => {
            return (
              <Stack direction={"column"} key={manager.lastName}>
                <Stack direction={"row"} spacing={2} sx={{}}>
                  <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
                    {manager.type ? manager.type : "-"}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="body1Hl" sx={{ fontWeight: "bold" }}>
                    {manager.firstName ? manager.firstName : ""}{" "}
                    {manager.lastName ? manager.lastName : "-"}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={2} sx={{}}>
                  <Typography variant="body1" sx={{}}>
                    {t(
                      "ucet.organization.info.management.birthDate",
                      "Datum narození:"
                    )}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="body1" sx={{}}>
                    {manager.birthDate ? manager.birthDate : "-"}
                  </Typography>
                </Stack>
                {/*  <Stack direction={"row"} spacing={2} sx={{}}>
                  <Typography variant="body1" sx={{}}>
                    {t(
                      "ucet.organization.info.management.description",
                      "Popis:"
                    )}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="body1" sx={{}}>
                    {manager.description ? manager.description : "-"}
                  </Typography>
                </Stack> */}
              </Stack>
            );
          })
        ) : (
          <Typography variant="body1" sx={{}}>
            {
              (t(
                "ucet.organization.info.organizationMembers.notFound",
                "Nenalezeno"
              ),
              "")
            }
          </Typography>
        )}
      </Stack>
    );
  }, [t, managementManagers]);

  const today = useMemo(() => {
    return new Date();
  }, []);

  const { loggedAccount } = useContext(IdmClientContext);

  const [showAddBoardMemberDialog, setShowAddBoardMemberDialog] =
    useState(false);
  const [showUpdateBoardMemberDialog, setShowUpdateBoardMemberDialog] =
    useState(false);
  const [selectedBoardMember, setSelectedBoardMember] =
    useState<ExtendedOrganizationBoardMember | null>(null);
  const [showRemoveBoardMemberDialog, setShowRemoveBoardMemberDialog] =
    useState(false);

  const onCloseHandler = useCallback(() => {
    setShowAddBoardMemberDialog(false);
    setShowUpdateBoardMemberDialog(false);
    setShowRemoveBoardMemberDialog(false);
  }, []);

  const getOnlyBoardMembers = useCallback(
    (members: ExtendedOrganizationBoardMember[]) => {
      if (members == null) return [];
      return members.map(
        ({
          description,
          typeCode,
          type,
          value,
          birthDate,
          titleBefore,
          firstName,
          lastName,
          titleAfter,
          from,
        }) => ({
          description,
          typeCode,
          type,
          value,
          birthDate,
          titleBefore,
          firstName,
          lastName,
          titleAfter,
          from,
        })
      );
    },
    []
  );

  const addOrUpdadteBoardMemberHandler = useCallback(async () => {
    if (allBoardMembers == null) return;
    const boardMembers = [...getOnlyBoardMembers(allBoardMembers)] as any;
    boardMembers.organizationId = props.organization.id;
    const result = await createOrUpdateOrganizationBoardMemberCall(
      boardMembers
    );
    const message = t(
      "ucet.organization.info.boardMemberAddedOrUpdated",
      "Změny byly uloženy"
    );
    if (typeof result === "object" && result) {
      showSuccess(message);
    }
    setUpdateNeeded(false);
  }, [
    props.organization.id,
    allBoardMembers,
    getOnlyBoardMembers,
    createOrUpdateOrganizationBoardMemberCall,
    showSuccess,
    t,
  ]);

  const addBoardMemberHandler = useCallback(
    (data: ExtendedOrganizationBoardMember) => {
      setShowAddBoardMemberDialog(false);
      setUpdateNeeded(true);
      /* console.log("ukladam data ", JSON.stringify(data)); */
      const prevData = allBoardMembers ?? [];
      const newData = [
        ...prevData,
        {
          id: boardCount.toString(),
          type: findName(data.typeCode),
          ...data,
        },
      ];
      /* console.log("newData", JSON.stringify(newData)); */
      setAllBoardMembers(newData);
      setBoardCount(boardCount + 1);
    },
    [boardCount, findName, allBoardMembers]
  );

  const updateBoardMemberHandler = useCallback(
    async (data: ExtendedOrganizationBoardMember) => {
      setShowUpdateBoardMemberDialog(false);
      setUpdateNeeded(true);
      const updatedMembers = allBoardMembers.map((member) => {
        if (member.id === data.id) {
          return data;
        }
        return member;
      });
      setAllBoardMembers(updatedMembers);
    },
    [allBoardMembers]
  );

  const removeBoardMemberHandler = useCallback(
    async (data: ExtendedOrganizationBoardMember) => {
      setShowRemoveBoardMemberDialog(false);
      setUpdateNeeded(true);
      const updatedMembers = allBoardMembers.filter(
        (member) => member.id !== data.id
      );
      setAllBoardMembers(updatedMembers);
    },
    [allBoardMembers]
  );

  const [updateNeeded, setUpdateNeeded] = useState(false);

  useEffect(() => {
    if (updateNeeded) {
      addOrUpdadteBoardMemberHandler();
    }
    //console.log("allBoardMembers", JSON.stringify(allBoardMembers));
  }, [allBoardMembers, addOrUpdadteBoardMemberHandler, updateNeeded]);

  return (
    <Paper variant="infoCard" sx={{ mt: 2 }}>
      {!isForeign && (
        <Stack direction={"row"}>
          <div style={{ flexGrow: 1 }} />
          <SmartButton
            variant="text"
            state={refreshMagnusWebState}
            onClick={() => refreshOrganizationData()}
          >
            {t("common.refreshMagnus", "Aktualizovat")}
          </SmartButton>
        </Stack>
      )}
      {isForeign && props.organization.parentId == null && (
        <Stack direction={"row"}>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="text"
            onClick={() => {
              setShowAddBoardMemberDialog(true);
            }}
          >
            {t("ucet.organization.info.boardMember.add", "Přidat")}
          </Button>
        </Stack>
      )}
      <Grid container infoCardMultiColumns>
        <Grid item md={6} xs={12}>
          <Stack direction={"column"} spacing={2} sx={{ alignItems: "center" }}>
            <Typography variant="h3Hl">
              {props.organization.displayName}
            </Typography>
            {props.organization.parentId ? null : (
              <>
                <Typography variant="body1">
                  {t("ucet.organization.info.ico", "IČO") +
                    " " +
                    props.organization.idNumber}
                </Typography>
                <Typography variant="body1">
                  {t("ucet.organization.info.legalForm", "Právní forma") + " "}
                  {props.organization.legalFormCode ? (
                    <Typography variant="body1Hl">
                      <ShowCode
                        codeTable="legalForm"
                        findCode={props.organization.legalFormCode}
                        dateOfVersion={today}
                        lang={
                          loggedAccount?.preferredLanguage === "en"
                            ? "en"
                            : "cs"
                        }
                      />
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Typography>
              </>
            )}
          </Stack>
          <Divider variant="middle" sx={{ mt: 2 }} />
          {props.organization.parentId ? (
            <DistaInfoListBlock {...organizationUnitData} />
          ) : (
            <DistaInfoListBlock {...contactData} />
          )}
        </Grid>
        {props.organization.parentId ? null : (
          <Grid item md={6} xs={12}>
            {executiveData}
            <Divider variant="middle" sx={{ my: 1 }} />
            {managementData}
          </Grid>
        )}
      </Grid>
      {showAddBoardMemberDialog && (
        <AddOrUpdateBoardMemberDialog
          onAddState={createOrUpdateOrganizationBoardMemberState}
          onDone={addBoardMemberHandler}
          onCancel={onCloseHandler}
        />
      )}
      {showUpdateBoardMemberDialog && (
        <AddOrUpdateBoardMemberDialog
          boardMember={selectedBoardMember}
          onAddState={createOrUpdateOrganizationBoardMemberState}
          onDone={updateBoardMemberHandler}
          onCancel={onCloseHandler}
        />
      )}
      {showRemoveBoardMemberDialog && (
        <DistaConfirmDialog
          onClose={onCloseHandler}
          open={true}
          okAction={{
            type: "button",
            children: t(
              "ucet.organization.info.boardMember.confirmDialog.ok",
              "Ano"
            ),
            onClick: () => {
              setShowRemoveBoardMemberDialog(false);
              return removeBoardMemberHandler(selectedBoardMember);
            },
          }}
          cancelAction={{
            type: "button",
            children: t(
              "ucet.organization.info.boardMember.confirmDialog.cancel",
              "Zrušit"
            ),
            onClick: () => onCloseHandler(),
          }}
          title={t(
            "ucet.organization.info.boardMember.confirmDialog.title",
            "Odebrat člena statutárního orgánu"
          )}
        >
          {t(
            "ucet.organization.info.boardMember.confirmDialog.removeBoardMember",
            "Opravdu chcete odebrat člena statutárního orgánu?"
          )}
        </DistaConfirmDialog>
      )}
    </Paper>
  );
};
