import { DialogContent, Stack } from "@mui/material";
import { DistaDialog, DistaDialogHeader } from "@sista/dista-ui";
import {
  SistaPlainDateElement,
  SmartButton,
  useRequiredValidation,
} from "@sista/library-stasi";
import { useEffect, useMemo } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { ExtendedOrganizationBoardMember } from "../../../components/content/organization/OrganizationDetailInfoContent";
import {
  useGetAllCodesOptions,
  useGetTypeName,
} from "../../../utils/useOrganizationBoardMemberTypes";

export type AddOrUpdateBoardMemberDialogProps = {
  onAddState: any;
  boardMember?: ExtendedOrganizationBoardMember;
  onDone: (data: ExtendedOrganizationBoardMember) => void;
  onCancel: () => void;
};

export type AddOrUpdateBoardMemberDialogForm = ExtendedOrganizationBoardMember;

export const AddOrUpdateBoardMemberDialog: React.FC<
  AddOrUpdateBoardMemberDialogProps
> = ({
  onAddState,
  boardMember,
  onDone,
  onCancel,
}: AddOrUpdateBoardMemberDialogProps) => {
  const { t } = useTranslation("ucet");
  const form = useForm<AddOrUpdateBoardMemberDialogForm>({
    defaultValues: boardMember ? { ...boardMember } : {},
  });

  const required = useRequiredValidation();
  const typeOptions = useGetAllCodesOptions();

  return (
    <DistaDialog open={true} onClose={onCancel}>
      <DistaDialogHeader
        onClose={onCancel}
        title={
          boardMember
            ? t(
                "ucet.foreignOrganization.addOrUpdateBordMember.updateTitle",
                "Upravit statutára"
              )
            : t(
                "ucet.foreignOrganization.addOrUpdateBordMember.addTitle",
                "Přidat statutára"
              )
        }
      />
      <DialogContent>
        <FormContainer formContext={form} onSuccess={onDone}>
          <Stack direction={"column"} spacing={2} sx={{ my: 2 }}>
            {/* <TextFieldElement
              name={"titleBefore"}
              label={t(
                "ucet.foreignOrganization.addOrUpdateBordMember.titleBefore",
                "Titul před"
              )}
            /> */}
            <TextFieldElement
              name={"firstName"}
              label={t(
                "ucet.foreignOrganization.addOrUpdateBordMember.firstName",
                "Jméno"
              )}
              validation={{ ...required }}
            />
            <TextFieldElement
              name={"lastName"}
              label={t(
                "ucet.foreignOrganization.addOrUpdateBordMember.lastName",
                "Příjmení"
              )}
              validation={{ ...required }}
            />
            {/* <TextFieldElement
              name={"titleAfter"}
              label={t(
                "ucet.foreignOrganization.addOrUpdateBordMember.titleAfter",
                "Titul za"
              )}
            /> */}
            <SistaPlainDateElement
              name="birthDate"
              label={t(
                "ucet.foreignOrganization.addOrUpdateBordMember.birthDate",
                "Datum narození"
              )}
              validation={{ ...required }}
            />
            <SelectElement
              name="typeCode"
              options={typeOptions}
              label={t(
                "ucet.foreignOrganization.addOrUpdateBordMember.type",
                "Typ"
              )}
              validation={{ ...required }}
            />
            <SistaPlainDateElement
              name="from"
              label={t(
                "ucet.foreignOrganization.addOrUpdateBordMember.from",
                "Den vzniku funkce"
              )}
              validation={{ ...required }}
            />
            <Stack direction={"row"} spacing={2}>
              <div style={{ flexGrow: 1 }} />
              <SmartButton variant="contained" state={onAddState}>
                {boardMember
                  ? t(
                      "ucet.foreignOrganization.addOrUpdateBordMember.updateButton",
                      "Upravit"
                    )
                  : t(
                      "ucet.foreignOrganization.addOrUpdateBordMember.addButton",
                      "Přidat"
                    )}
              </SmartButton>
            </Stack>
          </Stack>
        </FormContainer>
      </DialogContent>
    </DistaDialog>
  );
};
