import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import {
  countryCodes,
  countryLabels,
  countryName,
  Organization,
  RelationDTO,
  Right,
  SistaAutocompleteElement,
  SmartButton,
  useMaxLengthValidation,
  useMinLengthValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useIcoValidation } from "@sista/library-stasi/dist/components/form/validations/useIcoValidation";
import { ShowCode } from "@sista/service-codetables";
import { SelectCode } from "@sista/service-codetables";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { useSistaStateCodesFormRegister } from "../../../utils/useSistaStateCodesFormRegister";

export type ForegnOrganizationCreateOrUpdateContentProps = {
  organization: Organization;
  onOrganizationDone: (organization: Organization) => void;
  onRelationFound: (relation: RelationDTO[]) => void;
  onCancel?: () => void;
  onIsForeignCompanyChecked: (isForeignCompanyChecked: boolean) => void;
};

export type OrganizationForm = Organization;

export const ForegnOrganizationCreateOrUpdateContent: React.FC<
  ForegnOrganizationCreateOrUpdateContentProps
> = ({
  organization,
  onOrganizationDone,
  onRelationFound,
  onIsForeignCompanyChecked,
  onCancel,
}: ForegnOrganizationCreateOrUpdateContentProps) => {
  const { t } = useTranslation("ucet");
  const ico = useIcoValidation();
  const maxLength = useMaxLengthValidation(8);
  const minLength = useMinLengthValidation(8);
  const required = useRequiredValidation();
  const { loggedAccount } = useContext(IdmClientContext);

  const organizationForm = useForm<OrganizationForm>({
    defaultValues: organization,
    /* {
      displayName: organization.displayName ?? "",
      idNumber: organization.idNumber ?? "",
      vatId: organization.vatId ?? "",
      legalFormCode: organization.legalFormCode ?? "",
      idDataBox: organization.idDataBox ?? "",
      officialAddress: {
        street: organization.officialAddress?.street ?? "",
        streetNumber: organization.officialAddress?.streetNumber ?? "",
        district: organization.officialAddress?.district ?? "",
        city: organization.officialAddress?.city ?? "",
        zip: organization.officialAddress?.zip ?? "",
        locality: organization.officialAddress?.locality ?? "",
        region: organization.officialAddress?.region ?? "",
        state: organization.officialAddress?.state ?? "",
      },
    }, */
  });

  const rej = useRejstrikClient();

  const [getMyRelationsInOrganizationCall, getMyRelationsInOrganizationState] =
    useSafeSistaAsync(rej.getMyRelationsInOrganization);

  const [createForeignCompanyCall, createForeignCompanyState] =
    useSafeSistaAsync(rej.createForeignOrganization);
  const [updateForeignCompanyCall, updateForeignCompanyState] =
    useSafeSistaAsync(rej.updateForeignOrganization);

  const today = useMemo(() => {
    return new Date();
  }, []);

  const language = useMemo(() => {
    return loggedAccount?.preferredLanguage
      ? loggedAccount?.preferredLanguage === "en"
        ? "en"
        : "cs"
      : "cs";
  }, [loggedAccount?.preferredLanguage]);

  const isPending = useMemo(() => {
    return (
      createForeignCompanyState ||
      updateForeignCompanyState ||
      getMyRelationsInOrganizationState
    );
  }, [
    createForeignCompanyState,
    updateForeignCompanyState,
    getMyRelationsInOrganizationState,
  ]);

  const onSuccessHandler = useCallback(
    async (data: OrganizationForm) => {
      if (organization.id == null) {
        const resultOrganization = await createForeignCompanyCall({
          ...data,
        });
        if (typeof resultOrganization === "object" && resultOrganization) {
          const resultRelations = await getMyRelationsInOrganizationCall({
            organizationId: resultOrganization.id!,
          });
          if (typeof resultRelations === "object" && resultRelations) {
            onRelationFound(resultRelations);
            onIsForeignCompanyChecked(true);
            return onOrganizationDone(resultOrganization);
          }
        }
      }
      if (organization.id != null) {
        const resultOrganization = await updateForeignCompanyCall({
          organizationId: organization.id!,
          ...data,
        });
        if (typeof resultOrganization === "object" && resultOrganization) {
          const resultRelations = await getMyRelationsInOrganizationCall({
            organizationId: resultOrganization.id!,
          });
          if (typeof resultRelations === "object" && resultRelations) {
            onIsForeignCompanyChecked(true);
            onRelationFound(resultRelations);
            return onOrganizationDone(resultOrganization);
          }
        }
      }
    },
    [
      onOrganizationDone,
      onRelationFound,
      onIsForeignCompanyChecked,
      getMyRelationsInOrganizationCall,
      createForeignCompanyCall,
      updateForeignCompanyCall,
      organization?.id,
    ]
  );

  return (
    <FormContainer onSuccess={onSuccessHandler} formContext={organizationForm}>
      <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
        <Typography variant="h5" component="h5">
          {t(
            "ucet.organization.delegate.foreignCompany.basicInfo",
            "Základní informace"
          )}
        </Typography>
        <TextFieldElement
          name="displayName"
          label={t(
            "ucet.organization.delegate.foreignCompany.displayName",
            "Úřední název subjektu"
          )}
          validation={{ ...required }}
        />
        <TextFieldElement
          name="idNumber"
          label={t("ucet.organization.delegate.foreignCompany.idNumber", "IČO")}
          validation={{
            ...required,
          }}
        />
        <TextFieldElement
          name="vatId"
          label={t("ucet.organization.delegate.foreignCompany.vatId", "DIČ")}
          disabled={true}
        />
        <Stack direction={"row"} spacing={2}>
          <Typography variant="body1">
            {t(
              "ucet.organization.delegate.foreignCompany.legalForm",
              "Právní forma: "
            )}
          </Typography>
          <Box>
            <ShowCode
              codeTable="legalForm"
              findCode={organization?.legalFormCode}
              dateOfVersion={today}
              lang={loggedAccount?.preferredLanguage === "en" ? "en" : "cs"}
            />
          </Box>
        </Stack>
        <TextFieldElement
          name="idDataBox"
          label={t(
            "ucet.organization.delegate.foreignCompany.idDataBox",
            "ID datové schránky"
          )}
        />
        <Divider />
        <Typography variant="h5" component="h5">
          {t(
            "ucet.organization.delegate.foreignCompany.officialAddress.title",
            "Sídlo"
          )}
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextFieldElement
            name="officialAddress.street"
            label={t(
              "ucet.organization.delegate.foreignCompany.officialAddress.street",
              "Ulice"
            )}
            validation={{ ...required }}
            sx={{ width: "50%" }}
          />
          <TextFieldElement
            name="officialAddress.streetNumber"
            label={t(
              "ucet.organization.delegate.foreignCompany.officialAddress.streetNumber",
              "Číslo popisné"
            )}
            validation={{ ...required }}
            sx={{ width: "50%" }}
          />
        </Stack>
        <TextFieldElement
          name="officialAddress.district"
          label={t(
            "ucet.organization.delegate.foreignCompany.officialAddress.district",
            "Část obce"
          )}
        />
        <TextFieldElement
          name="officialAddress.city"
          label={t(
            "ucet.organization.delegate.foreignCompany.officialAddress.city",
            "Obec"
          )}
          validation={{ ...required }}
        />
        <TextFieldElement
          name="officialAddress.zip"
          label={t(
            "ucet.organization.delegate.foreignCompany.officialAddress.zip",
            "PSČ"
          )}
          validation={{ ...required }}
        />
        <TextFieldElement
          name="officialAddress.locality"
          label={t(
            "ucet.organization.delegate.foreignCompany.officialAddress.locality",
            "Okres"
          )}
        />
        <TextFieldElement
          name="officialAddress.region"
          label={t(
            "ucet.organization.delegate.foreignCompany.officialAddress.region",
            "Kraj"
          )}
        />
        <SelectCode
          {...useSistaStateCodesFormRegister(
            organizationForm,
            "officialAddress.stateCode",
            "officialAddress.state",
            true
          )}
          codeTable="listOfCountries"
          label={t(
            "ucet.organization.delegate.foreignCompany.officialAddress.state",
            "Stát"
          )}
          hierarchy="leafsOnly"
          dateOfVersion={today}
          multiple={false}
          lang={language}
        />
        <Right>
          {/* <Button onClick={onCancel} variant="text">
            {t("ucet.organization.delegate.foreignCompany.cancel", "Zrušit")}
          </Button> */}
          <SmartButton state={isPending}>
            {t(
              "ucet.organization.delegate.foreignCompany.createOrUpdate",
              "Pokračovat"
            )}
          </SmartButton>
        </Right>
      </Stack>
    </FormContainer>
  );
};
