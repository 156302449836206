import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const useSistaStateCodesFormRegister = (
  form: UseFormReturn<any>,
  fieldId: any,
  name: any,
  required: boolean
) => {
  const { t } = useTranslation("ucet");

  const requiredMessage = t(
    "ucet.organization.delegate.foreignCompany.required",
    "Povinné pole"
  );

  const formReqister = form.register;
  const formUnregister = form.unregister;

  useEffect(() => {
    formReqister(fieldId, {
      validate: (value, formValues) => {
        console.log("validate", value, formValues);
        if (required && (value == undefined || value == null || value == "")) {
          console.log("requiredMessage", requiredMessage);
          return requiredMessage;
        }
        return undefined;
      },
    });
    return () => {
      formReqister(fieldId, {
        validate: {},
      });
    };
  }, [formReqister, fieldId, name, requiredMessage, required]);

  const helperTextAllErrors = form.formState.errors["officialAddress"];

  const helperText = (helperTextAllErrors as any)?.stateCode?.message;
  const value = form.watch(fieldId) == null ? undefined : form.watch(fieldId);
  const onChange = (event: any, newValues: any) => {
    if (newValues == null) {
      form.setValue(fieldId, undefined);
      form.setValue(name, undefined);
      return;
    }
    form.setValue(fieldId, newValues.value);
    form.setValue(name, newValues.label);
  };

  return {
    value,
    onChange,
    helperText: helperText,
    error: helperText != null,
  };
};
