import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const allOwnerShipsTieTypesCodes = [1, 2, 4, 7];

export function useGetOwnershipTieTypeName() {
  const { t } = useTranslation("ucet");
  return useCallback(
    (code: number) => {
      //console.log("code", code);
      if (code === 1) {
        return t(
          "ucet.organizationPropertyTies.ownerTieType.generalPartner",
          "Komplementář"
        );
      } else if (code === 2) {
        return t(
          "ucet.organizationPropertyTies.ownerTieType.equiltyInterest",
          "Majetková účast"
        );
      } else if (code === 4) {
        return t(
          "ucet.organizationPropertyTies.ownerTieType.limitedPartner",
          "Komanditista"
        );
      } else if (code === 7) {
        return t(
          "ucet.organizationPropertyTies.ownerTieType.founder",
          "Zřizovatel / zakladatel"
        );
      } else {
        return t(
          "ucet.organizationPropertyTies.ownerTieType.unknown",
          "Neznámý"
        );
      }
    },
    [t]
  );
}

export const useGetAllOwnerShipTypesCodesOptions = () => {
  const findName = useGetOwnershipTieTypeName();
  return allOwnerShipsTieTypesCodes.map((code) => ({
    label: findName(code),
    id: code,
  }));
};
