import { DialogContent, Stack } from "@mui/material";
import { DistaDialog, DistaDialogHeader } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import { SmartButton, useRequiredValidation } from "@sista/library-stasi";
import { SelectCode } from "@sista/service-codetables";
import { useContext, useMemo } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { ExtendedOrganizationOwnership } from "../../../components/content/organization/OrganizationPropertyTIesContent";
import { useGetAllOwnerShipTypesCodesOptions } from "../../../utils/useOrganizationOwnerShipTypes";
import { useSistaStateForOwnerShipFormRegister } from "../../../utils/useSistaStateForOwnerShipFormRegister";
import { useDecimalValidation } from "../../validations/useDecimalValidation";

export type AddOrUpdateBoardMemberDialogProps = {
  ownership?: ExtendedOrganizationOwnership;
  onDone: (data: ExtendedOrganizationOwnership) => void;
  onCancel: () => void;
};

export type AddOrUpdateOwnershipDialogForm = ExtendedOrganizationOwnership;

export const AddOrUpdateOwnershipDialog: React.FC<
  AddOrUpdateBoardMemberDialogProps
> = ({ ownership, onDone, onCancel }: AddOrUpdateBoardMemberDialogProps) => {
  const { t } = useTranslation("ucet");
  const form = useForm<AddOrUpdateOwnershipDialogForm>({
    defaultValues: ownership ? { ...ownership } : {},
  });

  const { loggedAccount } = useContext(IdmClientContext);

  const required = useRequiredValidation();
  const validate2dec = useDecimalValidation(2);

  const typeOptions = useGetAllOwnerShipTypesCodesOptions();
  const today = useMemo(() => new Date(), []);
  const language = useMemo(() => {
    return loggedAccount?.preferredLanguage
      ? loggedAccount?.preferredLanguage === "en"
        ? "en"
        : "cs"
      : "cs";
  }, [loggedAccount?.preferredLanguage]);

  const tieTypeOptions = useMemo(() => {
    return [
      {
        id: -1,
        label: t(
          "ucet.foreignOrganization.adOrUpdateOwnership.property",
          "Majetková vazba"
        ),
      },
      {
        id: 1,
        label: t(
          "ucet.foreignOrganization.adOrUpdateOwnership.control",
          "Řídící vazba"
        ),
      },
    ];
  }, [t]);

  return (
    <DistaDialog open={true} onClose={onCancel}>
      <DistaDialogHeader
        onClose={onCancel}
        title={
          ownership
            ? t(
                "ucet.foreignOrganization.adOrUpdateOwnership.updateTitle",
                "Upravit vazbu"
              )
            : t(
                "ucet.foreignOrganization.adOrUpdateOwnership.addTitle",
                "Přidat vazbu"
              )
        }
      />
      <DialogContent>
        <FormContainer formContext={form} onSuccess={onDone}>
          <Stack direction={"column"} spacing={2} sx={{ my: 2 }}>
            <TextFieldElement
              name={"name"}
              label={t(
                "ucet.foreignOrganization.adOrUpdateOwnership.name",
                "Název"
              )}
              validation={{ ...required }}
            />
            <TextFieldElement
              name={"address"}
              label={t(
                "ucet.foreignOrganization.adOrUpdateOwnership.address",
                "Adresa"
              )}
              validation={{ ...required }}
            />
            <SelectElement
              name="direction"
              options={tieTypeOptions}
              label={t(
                "ucet.foreignOrganization.adOrUpdateOwnership.direction",
                "Typ vazby"
              )}
              validation={{ ...required }}
            />
            <SelectElement
              name="type_cd"
              options={typeOptions}
              label={t(
                "ucet.foreignOrganization.adOrUpdateOwnership.type",
                "Typ"
              )}
              validation={{ ...required }}
            />
            <TextFieldElement
              name={"value_pct"}
              label={t(
                "ucet.foreignOrganization.adOrUpdateOwnership.value_pct",
                "Podíl"
              )}
              validation={{ ...required, ...validate2dec }}
              type="number"
              inputProps={{ step: 0.01 }}
            />
            <SelectCode
              {...useSistaStateForOwnerShipFormRegister(
                form,
                "country_iso3",
                "state",
                true
              )}
              codeTable="listOfCountries"
              label={t(
                "ucet.organization.delegate.foreignCompany.officialAddress.state",
                "Stát"
              )}
              hierarchy="leafsOnly"
              dateOfVersion={today}
              multiple={false}
              lang={language}
            />
            <Stack direction={"row"} spacing={2}>
              <div style={{ flexGrow: 1 }} />
              <SmartButton variant="contained">
                {ownership
                  ? t(
                      "ucet.foreignOrganization.adOrUpdateOwnership.updateButton",
                      "Upravit"
                    )
                  : t(
                      "ucet.foreignOrganization.adOrUpdateOwnership.addButton",
                      "Přidat"
                    )}
              </SmartButton>
            </Stack>
          </Stack>
        </FormContainer>
      </DialogContent>
    </DistaDialog>
  );
};
